<template>
  <div :class="$style.root">
    <input :class="$style.input" ref="input" type="file" @change="onUpdate" />
    <slot v-bind="{ value, file, size, current, onUpdate, onDelete, onFocus }">
      <VTextField
        dense
        outlined
        readonly
        clearable
        hideDetails="auto"
        :loading="loading"
        :label="label"
        :value="file || value"
        :rules="validate"
        :appendIcon="icon"
        @click="onFocus"
        @click:append="onFocus"
        @click:clear.stop="onDelete"
      />
      <slot name="preview" v-bind="{ value, file, size, current, onUpdate, onDelete, onFocus }">
        <div v-if="current" class="mt-2">
          <a :href="current.url" class="text-decoration-none" target="_blank">
            <VImg v-if="current.src" :lazySrc="current.src" height="60" width="60" aspect-ratio="1" />
            <div :class="$style.file" v-else>
              <VIcon color="primary" large >mdi-file</VIcon>
              {{current.name}}
            </div>
          </a>
        </div>
      </slot>
    </slot>
  </div>
</template>

<script>
import { get, isFunction } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'FileInput',
  props: {
    type: { type: String },
    label: { type: String },
    icon: { type: String, default: 'mdi-file-image-outline' },
    name: { type: String, default: 'doc'},
    value: { type: [Number, String] },
    rules: { type: [Array, Function], default: () => [] },
    force: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      items: 'file/items',
    }),
    current() {
      const id = this.value;
      return get(this.items, id);
    },
    file() {
      return get(this.current, 'name');
    },
    size() {
      return get(this.current, 'size');
    },
    validate() {
      const size = this.size;
      const value = this.file || this.value;
      if (this.loading) return [];
      if (isFunction(this.rules)) return this.rules({ value, size });
      return this.rules;
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'file/fetchItem',
      uploadItem: 'file/uploadItem',
      deleteItem: 'file/deleteItem',
    }),
    onFocus() {
      this.$refs.input.click();
    },
    onFetch() {
      const id = this.value;
      if (id) this.fetchItem({ id });
    },
    onUpdate() {
      if (this.$refs.input.files[0]) {
        const name = this.name;
        const type = this.type;
        const data = new FormData();
        if (type) data.append('type', type);
        data.append(name, this.$refs.input.files[0]);
        this.$emit('update', { data });
        this.$refs.input.value = null;
        this.loading = true;
        this.uploadItem(data).then(({ id }) => {
          this.$emit('input', id);
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    onDelete() {
      const id = this.value;
      this.$refs.input.value = null;
      if (this.force && id) {
        this.loading = true;
        this.deleteItem({ id }).then(() => {
          this.$emit('input', '');
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      } else  {
        this.$emit('input', '');
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value && !this.current) {
          this.onFetch();
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  .file {
    border: 1px solid #dee2e6;
    padding: 10px;
  }
  .input {
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
</style>
