<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" />
    <DisclosureAgreement v-if="!empty" :id="agreement" :values="detail" @submit="onSubmit" />
  </div>
</template>

<script>
import { isEmpty, toString } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import DisclosureAgreement from '@/components/nda/DisclosureAgreement/DisclosureAgreement';
export default {
  name: 'DisclosureAgreementDetailView',
  components: {
    DisclosureAgreement,
  },
  props: {
    agreement: { type: String, required: true },
    template: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      pending: 'nda/pending',
      detail: 'nda/detail',
    }),
    empty() {
      return isEmpty(this.detail) && this.pending;
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'nda/fetchItem',
      updateItem: 'nda/updateItem',
    }),
    onSubmit: function({ values }) {
      this.updateItem({ values }).then((agreement) => {
        const id = this.agreement;
        const template = this.template;
        if (!id && agreement) {
          // Намеренно используем path а не name в $router. Чтобы явно контролировать trailing slash
          this.$router.push({ path: `/nda/?appId=${agreement}&template=${template}`});
        }
        if (agreement && (toString(id) === toString(agreement))) {
          this.fetchItem({ id, template });
        }
      });
    }
  },
  watch: {
    agreement: {
      immediate: true,
      handler: function() {
        const id = this.agreement;
        const template = this.template;
        this.fetchItem({ id, template });
      }
    }
  },
  beforeMount() {
    if (!this.template && !this.agreement) {
      window.location.href = '/'; // TODO: до формирования остальных разделов SPA
    }
  }
}
</script>

<style module lang="scss">
.root{
  position: relative;
}
</style>
